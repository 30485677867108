import axios from 'axios'
import consts from '../../consts'

// export function ShowForm(item){
//     return { type: 'PDVS_SHOW_FORM', payload: item  }
//     // console.log(item)
// }

// export function HideForm(){
//     return { type: 'PDVS_HIDE_FORM' }
// }

// export function get(){
//   return dispatch => {
//       axios.get(`${consts.API_URL}/pdvs`)
//       .then(resp => {
//           dispatch([
//             { type: 'PDVS_GET', payload: resp.data }
//           ])
//       })
//     }
// }

// export function getAccount(account){
//   // console.log(account)
//   return dispatch => {
//       axios.get(`${consts.API_URL}/accounts/${account.id}`)
//       .then(resp => {
//         // console.log('PDVS_GET_ACCOUNT resp: ',resp)
//           dispatch([
//             { type: 'PDVS_GET_ACCOUNT', payload: resp.data }
//           ])
//       })
//     }
// }

// export function blockAccount(account){
//   // console.log(account)
//   return dispatch => {
//       axios.put(`${consts.API_URL}/accounts/${account.id}/block`)
//       .then(resp => {
//         // console.log('PDVS_BLOCK_ACCOUNT resp: ',resp)
//         const configNotification = {
//           message: resp.data.message,
//           color: 'success'
//         }
//         dispatch([
//           { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
//           { type: 'PDVS_HIDE_FORM' },
//         ])
//           // dispatch([
//           //   { type: 'PDVS_BLOCK_ACCOUNT', payload: resp.data }
//           // ])
//       })
//     }
// }

// export function confirmAccount(account, limit){
//   // console.log(account)
//   // console.log(limit)
//   if(!limit || limit === ''){
//     return dispatch => {
//       const configNotification = {
//         message: 'Favor definir o limite',
//         color: 'danger'
//       }
//       dispatch([
//         { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
//       ])
//     }
//   }
//   return dispatch => {
//       axios.put(`${consts.API_URL}/accounts/${account.id}/confirm`, {limit})
//       .then(resp => {
//         console.log('PDVS_ACCEPT_ACCOUNT resp: ',resp)
//         const configNotification = {
//           message: resp.data.message,
//           color: 'success'
//         }
//         dispatch([
//           { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
//           { type: 'PDVS_HIDE_FORM' },
//         ])
//           // dispatch([
//           //   { type: 'PDVS_BLOCK_ACCOUNT', payload: resp.data }
//           // ])
//       })
//     }
// }
export function save(settings){
  console.log('settings save: ', settings)
    return dispatch => {
        axios.put(`${consts.API_URL}/settings`, {...settings})
        .then(resp => {
          console.log('resp: ',resp)
          const configNotification = {
            message: resp.data.message,
            color: 'success'
          }
          dispatch([
            { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
          ])
            // dispatch([
            //   { type: 'PDVS_BLOCK_ACCOUNT', payload: resp.data }
            // ])
        })
        .catch(e => {
          console.log(e.response)
          const configNotification = {
            message: 'Erro ao salvar.',
            color: 'danger'
          }
          dispatch([
            { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
          ])
        })
      }
}
