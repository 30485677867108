import React from "react";
import axios from "axios"
import consts from '../../consts'
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Icon from "@material-ui/core/Icon";

import Warning from "@material-ui/icons/Warning";
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import People from "@material-ui/icons/People";
import Map from "@material-ui/icons/Map";
import PinDrop from "@material-ui/icons/PinDrop";
import SentimentSatisfied from "@material-ui/icons/SentimentSatisfied";

// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import Danger from "components/Typography/Danger.jsx";

import {
  totalRegistersByDate,
  usuariosQMaisTwitam,
  localizacao,
  sentimentos,


  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart
} from "./charts0.jsx";

import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";

class Charts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      totalCustomers: 0,
      totalPdvs: 0,
      totalPdvsAtivos: 0,
      totalUsersTransfers: { labels: [], series: [] },
      totalRegistersByDate: { labels: [], series: [] },
      totalPdvsTransfers: { labels: [], series: [] },




      totalUsersTalkingAbout: 0,
      locations: { labels: [], series: [] },
    };

  }

  componentWillMount(){
    this.getAll()
  }

  getAll(){
    this.getTotalCustomers()
    this.getTotalPdvs()
    this.getTotalPdvsAtivos()
    this.getTotalRegistersByDate()
    this.getTotalUsersTransfers()
    this.getTotalPdvsTransfers()



    // this.getTotalReweeteds()
    // this.getTotalUsersTalkingAbout()
    // this.getLocations()
  }

  getTotalCustomers(){
    axios.get(`${consts.API_URL}/dashboard/total-customers`)
    .then(resp => {
        const totalCustomers = resp.data
        this.setState({ totalCustomers })
    })
  }

  getTotalPdvs(){
    axios.get(`${consts.API_URL}/dashboard/total-pdvs`)
    .then(resp => {
        const totalPdvs = resp.data
        this.setState({ totalPdvs })
    })
  }

  getTotalPdvsAtivos(){
    axios.get(`${consts.API_URL}/dashboard/total-pdvs-ativos`)
    .then(resp => {
        const totalPdvsAtivos = resp.data
        this.setState({ totalPdvsAtivos })
    })
  }

  getTotalRegistersByDate(){
    axios.get(`${consts.API_URL}/dashboard/total-registers-by-date`)
    .then(resp => {
        const labels = resp.data.map(data => { return data.date })
        const series = [resp.data.map(data => { return data.total })]
        const totalRegistersByDate = { labels, series }
        this.setState({ totalRegistersByDate })
    })
  }

  getTotalUsersTransfers(){
    axios.get(`${consts.API_URL}/dashboard/total-customers-transfers`)
    .then(resp => {
      // console.log(resp)
        const labels = resp.data.map(data => { return data.user_screen_name })
        const series = [resp.data.map(data => { return data.total })]
        const totalUsersTransfers = { labels, series }
        this.setState({ totalUsersTransfers })
    })
  }

  getTotalPdvsTransfers(){
    axios.get(`${consts.API_URL}/dashboard/total-pdvs-transfers`)
    .then(resp => {
        console.log(resp)
        const labels = resp.data.map(data => { return data.user_screen_name })
        const series = [resp.data.map(data => { return data.total })]
        const totalPdvsTransfers = { labels, series }
        this.setState({ totalPdvsTransfers })
    })
  }











  // getTotalReweeteds(){
  //   axios.get(`${consts.API_URL}/dashboard/total-retweeteds`)
  //   .then(resp => {
  //       const totalReweeteds = resp.data
  //       this.setState({ totalReweeteds })
  //   })
  // }
  //
  // getTotalUsersTalkingAbout(){
  //   axios.get(`${consts.API_URL}/dashboard/total-users-talking-about`)
  //   .then(resp => {
  //       const totalUsersTalkingAbout = resp.data
  //       this.setState({ totalUsersTalkingAbout })
  //   })
  // }
  //
  // getLocations(){
  //   axios.get(`${consts.API_URL}/dashboard/locations`)
  //   .then(resp => {
  //       const labels = resp.data.map(data => { return `${data.place_full_name}` })
  //       const series = resp.data.map(data => { return data.total })
  //       const locations = { labels, series }
  //       // console.log(locations)
  //       this.setState({ locations })
  //   })
  // }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <People />
                </CardIcon>
                <p className={classes.cardCategory}>Consumidores</p>
                <h3 className={classes.cardTitle}>{this.state.totalCustomers}</h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Map />
                </CardIcon>
                <p className={classes.cardCategory}>PDVs</p>
                <h3 className={classes.cardTitle}>{this.state.totalPdvs}</h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Map />
                </CardIcon>
                <p className={classes.cardCategory}>PDVs Ativos</p>
                <h3 className={classes.cardTitle}>{this.state.totalPdvsAtivos}</h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <DateRange />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Total de cadastros por data
                </h4>
              </CardHeader>
              <CardBody>
                <ChartistGraph
                  data={this.state.totalRegistersByDate}
                  type="Line"
                  options={totalRegistersByDate.options}
                  listener={totalRegistersByDate.animation}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>


        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>
                  Clientes que mais transacionam
                </h4>
              </CardHeader>
              <CardBody>
                <ChartistGraph
                  data={this.state.totalUsersTransfers}
                  type="Bar"
                  options={usuariosQMaisTwitam.options}
                  listener={usuariosQMaisTwitam.animation}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>
                  PDVs que mais transacionam
                </h4>
              </CardHeader>
              <CardBody>
                <ChartistGraph
                  data={this.state.totalPdvsTransfers}
                  type="Bar"
                  options={usuariosQMaisTwitam.options}
                  listener={usuariosQMaisTwitam.animation}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {
        (false) ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <PinDrop />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Localização</h4>
                </CardHeader>
                <CardBody>
                  <ChartistGraph
                    data={this.state.locations}
                    type="Pie"
                    options={localizacao.options}
                    />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SentimentSatisfied />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Sentimentos</h4>
                </CardHeader>
                <CardBody>
                  <ChartistGraph
                    data={sentimentos.data}
                    type="Pie"
                    options={sentimentos.options}
                    />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : null
        }


      </div>
    );
  }
}

export default withStyles(chartsStyle)(Charts);
