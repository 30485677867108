import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReactExport from "react-export-excel";

import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  ...dashboardStyle,
  ...sweetAlertStyle,
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alert: null,
      show: false,
    };
  }

  componentWillMount() {}

  componentDidMount() {
    this.get();
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  delete(item) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            axios.delete(`${consts.API_URL}/banners/${item.id}`).then((resp) => {
              var data = this.state.data;
              data.find((o, i) => {
                if (o.id === item.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  data.splice(i, 1);
                  return true;
                }
                return false;
              });
              this.setState({ data: data });
            });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como recuperar o registro ao excluir!
        </SweetAlert>
      ),
    });
  }

  get() {
    axios.get(`${consts.API_URL}/banners`).then((resp) => {
      const data = resp.data.result;
      this.setState({ data });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                <Button
                  color="info"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowForm()}
                >
                  Adicionar
                </Button>
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {this.state.data.map((item) => {
            return (
              <GridItem key={item.id} xs={12} sm={12} md={4}>
                <Card product className={classes.cardHover}>
                  <CardHeader image className={classes.cardHeaderHover}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={item.image} alt="..." />
                    </a>
                  </CardHeader>
                  <CardBody>
                    <div className={classes.cardHoverUnder}>
                      {/* <Tooltip
                        id="tooltip-top"
                        title="Editar"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button color="success" simple justIcon>
                          <Edit className={classes.underChartIcons} />
                        </Button>
                      </Tooltip> */}
                      <Tooltip
                        id="tooltip-top"
                        title="Excluir"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button color="danger" simple justIcon onClick={() => this.delete(item)}>
                          <CloseIcon className={classes.underChartIcons} />
                        </Button>
                      </Tooltip>
                    </div>
                    <h4 className={classes.cardProductTitle}>
                      <a href={item.link} target="_blank">
                        {item.link}
                      </a>
                    </h4>
                    <p className={classes.cardProductDesciprion}>{item.bt_title}</p>
                    <p className={classes.cardProductDesciprion}>
                      {item.app === "customer" ? "Consumidor" : ""}
                      {item.app === "pdv" ? "PDV" : ""}
                      {item.app === "pdv-desktop" ? "PDV Desktop" : ""}
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            );
          })}
        </GridContainer>
      </div>
    );
  }

  render1() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                <Button
                  color="info"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowForm()}
                >
                  Adicionar
                </Button>
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  filterable
                  columns={[
                    {
                      Header: "Nome",
                      accessor: "name",
                    },
                    {
                      Header: "E-mail",
                      accessor: "email",
                    },
                    {
                      Header: "Perfil",
                      accessor: "profile",
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      save,
      // get
    },
    dispatch
  );
const MemberList = withStyles(styles)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
