import React from "react";
import Form from './Form'
import If from '../../common/operators/If'

// React Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
} from './Actions'

class Main extends React.Component {

  constructor(props) {
    super(props);
    console.log(props)
  }

  render() {
    return (
      <div>
        <Form />
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state)
  return {
    showForm: state.settings.showForm,
    showBookingForm: state.settings.showBookingForm,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Main)
