// const API_URL = "http://127.0.0.1:3001";
const API_URL = "https://brasal-retornavel-api-hom.herokuapp.com";
// const API_URL = "https://brasal-retornavel-api.herokuapp.com";

const OAPI_URL = API_URL;
export default {
  API_URL,
  OAPI_URL,
  UserKey: "_ecoke_admin_dev",
  GoogleKey: "AIzaSyBzcxyN36gXhUqIATGXiRJzhjCZP1cay60",
};
