const INITIAL_BANNER = {
  bt_title: "",
  app: "",
  image: "",
  link: "",
};
const INITIAL_STATE = {
  showForm: false,
  loading: false,
  banner: INITIAL_BANNER,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_BANNER)
  // console.log(state);
  // console.log(action)
  switch (action.type) {
    case "BANNERS_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        banner: action.payload === undefined ? INITIAL_BANNER : action.payload,
      };
    case "BANNERS_HIDE_FORM":
      return { ...state, showForm: false, banner: INITIAL_BANNER };
    case "BANNERS_LOADING":
      return { ...state, loading: true };
    case "BANNERS_LOADED":
      return { ...state, loading: false };

    case "BANNERS_GET":
      return { ...state, list: action.payload };
    case "BANNERS_GET_CLIENT":
      return { ...state, cliente: action.payload };
    case "BANNERS_GET_BANNER":
      return {
        ...state,
        banner: {
          ...state.banner,
          name: action.payload.NOME,
          vendedor: action.payload.VENDEDOR,
        },
      };
    default:
      return state;
  }
};
