import axios from "axios";
import consts from "../../consts";

export function ShowForm(item) {
  return { type: "BANNERS_SHOW_FORM", payload: item };
}

export function HideForm() {
  return { type: "BANNERS_HIDE_FORM" };
}

// export function get(){
//   return dispatch => {
//       axios.get(`${consts.API_URL}/banners`)
//       .then(resp => {
//         console.log(resp)
//           dispatch([
//             { type: 'BANNERS_GET', payload: resp.data }
//           ])
//       })
//     }
// }

export function save(user) {
  return (dispatch) => {
    dispatch([{ type: "BANNERS_LOADING" }]);
    axios
      .post(`${consts.API_URL}/banners`, user)
      .then((resp) => {
        // console.log(resp)
        const configNotification = {
          message: "Banner cadastrado com sucesso",
          color: "success",
        };
        dispatch([
          {
            type: "MAIN_SHOW_NOTIFICATION",
            showNotification: true,
            payload: configNotification,
          },
          { type: "BANNERS_HIDE_FORM" },
          { type: "BANNERS_LOADED" },
        ]);
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors > 0
        ) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            const configNotification = {
              message: error,
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
              { type: "BANNERS_LOADED" },
            ]);
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          // console.log('e.response.data.message: ', e.response.data.message)
          // toastr.error('Erro', e.response.data.message)

          const configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
            { type: "BANNERS_LOADED" },
          ]);
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          const configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
            { type: "BANNERS_LOADED" },
          ]);
        }
      });
  };
}
