import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from 'axios'
import consts from '../../consts'

import If from '../../common/operators/If'

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// React Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  ShowForm,
  // save,
  get,
} from './Actions'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import ZoomInIcon from "@material-ui/icons/ZoomIn";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      reportData: [],
      alert: null,
      show: false
    };
  }

  componentWillMount(){
    this.get();
  }

  componentDidMount(){
    // this.getReportData();
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  // delete(item){
  //   this.setState({
  //     alert: (
  //       <SweetAlert
  //         warning
  //         style={{ display: "block", marginTop: "-100px" }}
  //         title="Tem certeza?"
  //         onConfirm={() => {
  //           // console.log(item)
  //           axios.delete(`${consts.API_URL}/users/${item.id}`)
  //           .then(resp => {
  //             var data = this.state.data;
  //             data.find((o, i) => {
  //               if (o.id === item.id) {
  //                 // here you should add some custom code so you can delete the data
  //                 // from this component and from your server as well
  //                 data.splice(i, 1);
  //                 return true;
  //               }
  //               return false;
  //             });
  //             this.setState({ data: data });
  //           })
  //           this.hideAlert()
  //         }}
  //         onCancel={() => this.hideAlert()}
  //         confirmBtnCssClass={
  //           this.props.classes.button + " " + this.props.classes.success
  //         }
  //         cancelBtnCssClass={
  //           this.props.classes.button + " " + this.props.classes.danger
  //         }
  //         confirmBtnText="Sim, excluir!"
  //         cancelBtnText="Cancelar"
  //         showCancel
  //       >
  //         Não tem como recuperar o registro ao excluir!
  //       </SweetAlert>
  //     )
  //   });
  // }

  setData(result){
    var data = result.map(item => {
      return {
        id: item.id,
        from: (item.nameFrom && item.nameFrom !== '') ? item.nameFrom : item.descriptionFrom,
        to: (item.nameTo && item.nameTo !== '') ? item.nameTo : item.descriptionTo,
        virtual: (item.amount_virtual) ? item.amount_virtual : 0,
        gift: (item.amount_gift) ? item.amount_gift : 0,
        physical: (item.amount_physical) ? item.amount_physical : 0,
        created_at: new Date(item.createdAt).toLocaleString('pt-BR')
      };
    })
    this.setState({data})
  }

  get(){
    // console.log('this.props: ', this.props)
    if(
      !this.props
      ||
      !this.props.actualState
      ||
      !this.props.actualState.start
    ){
      this.props.ShowForm()
      return false;
    }

    const that = this
    const  options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    // const start = new Date().toLocaleString('pt-BR', options)
    // const start = new Date().toLocaleString('en-US', options)
    const originalStart = this.props.actualState.start.split('/')
    const originalEnd = this.props.actualState.end.split('/')
    // console.log(originalStart)

    const start = new Date(`${originalStart[2]}-${originalStart[1]}-${originalStart[0]} 00:00:00`)
    const end = new Date(`${originalEnd[2]}-${originalEnd[1]}-${originalEnd[0]} 00:00:00`)

    const formatedStart = `${start.getFullYear()}-${(start.getMonth() < 9) ? '0' : ''}${start.getMonth() + 1}-${(start.getDate() < 10) ? '0' : ''}${start.getDate()}`
    const formatedEnd = `${end.getFullYear()}-${(end.getMonth() < 9) ? '0' : ''}${end.getMonth() + 1}-${(end.getDate() < 10) ? '0' : ''}${end.getDate()}`
    // console.log(start)
    const url = `${consts.API_URL}/transfers/report?start=${formatedStart}&end=${formatedEnd}`
    // console.log
    axios.get(url)
    .then(resp => {
      // console.log(resp)
      that.setData(resp.data)
      that.setState({reportData: resp.data})
      // that.getReportData()
    })
  }

  renderDownloadXLS(){
    const { classes } = this.props;
    // console.log('renderDownloadXLS')
    // return Promise.all([
    // ])

    // console.log(report)
    return (
            <ExcelFile element={
                <Button
                  color="info"
                  className={classes.updateProfileButton}
                  >
                  Download XLS
                </Button>
              }>
                <ExcelSheet data={this.state.reportData} name="Employees">
                    <ExcelColumn label="De"
                                 value={(col) => {
                                   // console.log(col)
                                   return (col.nameFrom && col.nameFrom !== '') ? col.nameFrom : col.descriptionFrom
                                 }}
                    />
                    <ExcelColumn label="Para"
                                   value={(col) => {
                                     return (col.nameTo && col.nameTo !== '') ? col.nameTo : col.descriptionTo
                                   }}
                    />
                    <ExcelColumn label="Virtual"
                                   value={(col) => {
                                     return (col.amount_virtual) ? col.amount_virtual : 0
                                   }}
                    />
                    <ExcelColumn label="Brinde"
                                   value={(col) => {
                                     return (col.amount_gift) ? col.amount_gift : 0
                                   }}
                    />
                    <ExcelColumn label="Físicas"
                                   value={(col) => {
                                     return (col.amount_physical) ? col.amount_physical : 0
                                   }}
                    />
                    <ExcelColumn label="Data"
                                   value={(col) => {
                                     return (col.createdAt) ? new Date(col.createdAt).toLocaleString('pt-BR') : ''
                                   }}
                    />
                </ExcelSheet>
            </ExcelFile>
        );
  }

  render() {
    const { classes } = this.props;
    const that = this
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                {
                  (
                    <h4 className={classes.cardIconTitle} style={{color: '#000'}}>Período - {this.props.actualState.start} a {this.props.actualState.end}</h4>
                  )
                }
                {this.renderDownloadXLS()}
                <Button
                  className={classes.updateProfileButton}
                  onClick={() => that.props.ShowForm()}
                  >
                  Nova pesquisa
                </Button>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  filterable
                  manual={true}
                  onFetchData={(state, instance) => {
                    // console.log('teste')
                  }}
                  columns={[
                    {
                      Header: "De",
                      accessor: "from"
                    },
                    {
                      Header: "Para",
                      accessor: "to"
                    },
                    {
                      Header: "Virtual",
                      accessor: "virtual"
                    },
                    {
                      Header: "Física",
                      accessor: "physical"
                    },
                    {
                      Header: "Brindes",
                      accessor: "gift"
                    },
                    {
                      Header: "Data",
                      accessor: "created_at"
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = state => {
  // console.log(state)
  return {
    ShowForm: state.transfers.ShowForm,
    showForm: state.transfers.showForm,
    actualState: state.transfers.actualState,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  ShowForm,
  // save,
  get
}, dispatch)
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(mapStateToProps, mapDispatchToProps)(MemberList)
