import React from "react";

// React Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import consts from '../../consts'
import {
  HideForm,
  get
  // save,
  // getAccount,
  // blockAccount,
  // confirmAccount
} from './Actions'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment"

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import CustomInputDate from "components/CustomInput/CustomInputDate.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import If from '../../common/operators/If'

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props: ',props)
    // console.log('props.account: ',props.account)
    // console.log('props.account.sap: ',props.account.sap)
    this.state = {
      start: props.start,
      end: props.end,
    };
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
    this.get();
  }



  get(){
    // console.log('teste')
    // this.props.getAccount(this.props.account)
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick(){
    this.props.HideForm(this.state)
  }

  // blockButtonClick(){
  //   this.props.blockAccount(this.props.account)
  // }
  //
  // acceptButtonClick(){
  //   this.props.confirmAccount(this.props.account, this.state.creditLimit)
  // }



  // saveButtonClick(){
    // console.log(this.props.user)
    // var user = {...this.props.user}
    // user.name = this.props.user.name
    // user.email = this.state.registerEmail
    // user.profile = this.state.profile
    // user.cpf = this.state.registerCPF
    // user.vendedor = this.props.user.vendedor
    // console.log(user)

    // var user = {
    //   name: this.state.name,
    // }
    // this.props.save(user)
  // }



  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value <= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }
  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)
    return (
      <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Transferências</h4>
            </CardHeader>
            <CardBody>
              <form>
                <h4 className={classes.cardIconTitle}>Período</h4>
                <GridContainer>
                  <GridItem xs={6}>
                    <CustomInputDate
                      success={this.state.startState === "success"}
                      error={this.state.startState === "error"}
                      labelText={
                        (!this.state.start || this.state.start === '') ?
                        <span>
                          Início
                        </span>
                        : null
                      }
                      id="start"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => this.change(event, "start", "length", 10),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInputDate
                      success={this.state.endState === "success"}
                      error={this.state.endState === "error"}
                      labelText={
                        (!this.state.end || this.state.end === '') ?
                        <span>
                          Fim
                        </span>
                        : null
                      }
                      id="end"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => this.change(event, "end", "length", 10)
                      }}
                    />
                  </GridItem>
                </GridContainer>

              </form>
            </CardBody>
            <CardFooter>
                <Button color="info" onClick={() => this.cancelButtonClick()}>
                  Carregar
                </Button>
            </CardFooter>
          </Card>
        </GridItem>

      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  // console.log('mapStateToProps: ', state)
  return (
    {
        HideForm: state.transfers.HideForm,
        user: state.transfers.user,
        account: state.transfers.account
    }
  )
}
const mapDispatchToProps = dispatch => bindActionCreators({
  HideForm,
  get,
  // save,
  // getAccount,
  // blockAccount,
  // confirmAccount,
}, dispatch)
const ReturnClassDecorated = withStyles(validationFormsStyle)(ValidationForms);
export default connect(mapStateToProps, mapDispatchToProps)(ReturnClassDecorated)
