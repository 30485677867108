import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch } from "react-router-dom";

import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import promise from 'redux-promise'
import multi from 'redux-multi'
import thunk from 'redux-thunk'

// import indexRoutes from "routes/index.jsx";
import Main from "routes/index.jsx";
// import AuthOrApp from './main/authOrApp'
import reducers from './reducers'

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

const hist = createBrowserHistory();

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
      && window.__REDUX_DEVTOOLS_EXTENSION__()
const store = applyMiddleware(multi, thunk, promise)(createStore)(reducers, devTools)

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Main />
      </Switch>
    </Router>
  </Provider>
  ,document.getElementById("root")
);
