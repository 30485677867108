import axios from 'axios'
import consts from '../../consts'

export function ShowForm(item){
    return { type: 'CUSTOMERS_SHOW_FORM', payload: item  }
    // console.log(item)
}

export function HideForm(){
    return { type: 'CUSTOMERS_HIDE_FORM' }
}

export function get(){
  console.log('CUSTOMERS_GET')
  return dispatch => {
      axios.get(`${consts.API_URL}/customers`)
      .then(resp => {
        console.log(resp)
          dispatch([
            { type: 'CUSTOMERS_GET', payload: resp.data }
          ])
      })
    }
}

// export function getAccount(account){
//   // console.log(account)
//   return dispatch => {
//       axios.get(`${consts.API_URL}/accounts/${account.id}`)
//       .then(resp => {
//         // console.log('CUSTOMERS_GET_ACCOUNT resp: ',resp)
//           dispatch([
//             { type: 'CUSTOMERS_GET_ACCOUNT', payload: resp.data }
//           ])
//       })
//     }
// }

// export function blockAccount(account){
//   // console.log(account)
//   return dispatch => {
//       axios.put(`${consts.API_URL}/accounts/${account.id}/block`)
//       .then(resp => {
//         // console.log('CUSTOMERS_BLOCK_ACCOUNT resp: ',resp)
//         const configNotification = {
//           message: resp.data.message,
//           color: 'success'
//         }
//         dispatch([
//           { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
//           { type: 'CUSTOMERS_HIDE_FORM' },
//         ])
//           // dispatch([
//           //   { type: 'CUSTOMERS_BLOCK_ACCOUNT', payload: resp.data }
//           // ])
//       })
//     }
// }

// export function confirmAccount(account, limit){
//   // console.log(account)
//   // console.log(limit)
//   if(!limit || limit === ''){
//     return dispatch => {
//       const configNotification = {
//         message: 'Favor definir o limite',
//         color: 'danger'
//       }
//       dispatch([
//         { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
//       ])
//     }
//   }
//   return dispatch => {
//       axios.put(`${consts.API_URL}/accounts/${account.id}/confirm`, {limit})
//       .then(resp => {
//         console.log('CUSTOMERS_ACCEPT_ACCOUNT resp: ',resp)
//         const configNotification = {
//           message: resp.data.message,
//           color: 'success'
//         }
//         dispatch([
//           { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
//           { type: 'CUSTOMERS_HIDE_FORM' },
//         ])
//           // dispatch([
//           //   { type: 'CUSTOMERS_BLOCK_ACCOUNT', payload: resp.data }
//           // ])
//       })
//     }
// }

// export function save(user){
//   return dispatch => {
//     if(user.id){
//       axios.put(`${consts.API_URL}/pdvs/${user.id}`, user)
//       .then(resp => {
//         var data = JSON.parse(localStorage.getItem(consts.UserKey))
//         // console.log(data)
//
//         if(data.user.id === resp.data.id){
//           var newData = {user: resp.data, token: data.token}
//           localStorage.setItem(consts.UserKey, JSON.stringify(newData))
//         }
//         // console.log(resp.data)
//         const configNotification = {
//           message: 'Usuário atualizado com sucesso',
//           color: 'success'
//         }
//         dispatch([
//           { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
//           { type: 'CUSTOMERS_HIDE_FORM' },
//         ])
//       })
//       .catch(e => {
//         if(e.response && e.response.data && e.response.data.errors && e.response.data.errors  > 0){
//             e.response.data.errors.forEach(
//                 error => {
//                   // toastr.error('Erro', error))
//                   const configNotification = {
//                         message: error,
//                         color: 'danger'
//                       }
//                   dispatch([
//                       { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
//                   ])
//             })
//         }else if(e.response && e.response.data && e.response.data.message){
//             // console.log('e.response.data.message: ', e.response.data.message)
//             // toastr.error('Erro', e.response.data.message)
//
//             const configNotification = {
//                   message: e.response.data.message,
//                   color: 'danger'
//                 }
//             dispatch([
//                 { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
//             ])
//         }else{
//             // toastr.error('Erro', 'Erro inesperado.')
//             const configNotification = {
//                   message: 'Erro inesperado.',
//                   color: 'danger'
//                 }
//             dispatch([
//                 { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
//             ])
//         }
//
//       })
//     }else{
//       axios.post(`${consts.API_URL}/users`, user)
//       .then(resp => {
//         // console.log(resp)
//         const configNotification = {
//           message: 'Usuário cadastrado com sucesso',
//           color: 'success'
//         }
//         dispatch([
//           { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
//           { type: 'CUSTOMERS_HIDE_FORM' }
//         ])
//       })
//       .catch(e => {
//         if(e.response && e.response.data && e.response.data.errors && e.response.data.errors  > 0){
//             e.response.data.errors.forEach(
//                 error => {
//                   // toastr.error('Erro', error))
//                   const configNotification = {
//                         message: error,
//                         color: 'danger'
//                       }
//                   dispatch([
//                       { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
//                   ])
//             })
//         }else if(e.response && e.response.data && e.response.data.message){
//             // console.log('e.response.data.message: ', e.response.data.message)
//             // toastr.error('Erro', e.response.data.message)
//
//             const configNotification = {
//                   message: e.response.data.message,
//                   color: 'danger'
//                 }
//             dispatch([
//                 { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
//             ])
//         }else{
//             // toastr.error('Erro', 'Erro inesperado.')
//             const configNotification = {
//                   message: 'Erro inesperado.',
//                   color: 'danger'
//                 }
//             dispatch([
//                 { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
//             ])
//         }
//
//       })
//     }
//   }
// }
