import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from 'axios'
import consts from '../../consts'

import If from '../../common/operators/If'

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// React Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  ShowForm,
  ShowBookingForm,
  save,
  // get
} from './Actions'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircle";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";



const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const statuses = [
  'Disponível',
  'Reservada',
  'Emplacado'
]
var empresas = []
empresas[1] = 'Volkswagen'
empresas[5] = 'Ford'

var revendas = []
revendas[1] = [
  '',
  'Sia',
  'Ceilândia',
  'Taguatinga'
]
revendas[5] = [
  '',
  'Taguatinga',
  'Ceilândia',
]

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alert: null,
      show: false,
      me: JSON.parse(localStorage.getItem(consts.UserKey)).user,
      token: JSON.parse(localStorage.getItem(consts.UserKey)).token,
      statuses: statuses,
      empresas: empresas,
      revendas: revendas
    };

  }

  componentWillMount(){
    this.get();
  }

  componentDidMount(){

  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  delete(item){
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {

            axios.delete(`${consts.API_URL}/queries/${item.id}`)
            .then(resp => {
              var data = this.state.data;
              data.find((o, i) => {
                if (o.id === item.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  data.splice(i, 1);
                  return true;
                }
                return false;
              });
              this.setState({ data: data });
            })
            this.hideAlert()
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como recuperar o registro ao excluir!
        </SweetAlert>
      )
    });
  }

  doneBooking(item){
    // var plate = {...item}

    // return false;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            var plate = {...item}

            plate.status = 2
            // return false;

            this.props.save(plate)
            var data = this.state.data;
            data.find((o, i) => {
              if (o.id === item.id) {


                // here you should add some custom code so you can delete the data
                // from this component and from your server as well
                data[i] = plate;
                // data.splice(i, 1);
                return true;
              }
              return false;
            });
            this.translateData(data);
            this.hideAlert()
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Sim, finalizar a reserva!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como voltar atrás!
        </SweetAlert>
      )
    });
  }

  cancelBooking(item){
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            var plate = {...item}
            plate.empresa = 0
            plate.revenda = 0
            plate.proposta = 0
            plate.cliente = ''
            plate.status = 0
            this.props.save(plate)

            var data = [...this.state.data];
            data.find((o, i) => {
              if (o.id === item.id) {


                // here you should add some custom code so you can delete the data
                // from this component and from your server as well
                data[i] = plate;
                // data.splice(i, 1);
                return true;
              }
              return false;
            });
            this.setData(data);
            this.hideAlert()
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Sim, cancelar a reserva!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como recuperar o registro!
        </SweetAlert>
      )
    });

  }

  translateData(data){
    var result = []

    for (var i = 0; i < data.length; i++) {
      var item = data[i]


      if(item.empresa){
        for (var key in this.state.empresas) {

          if(item.empresa === this.state.empresas[key]){
            item.empresa = parseInt(key)
          }
        }

        for (var key in this.state.revendas[item.empresa]) {

          if(item.revenda === this.state.revendas[item.empresa][key]){
            item.revenda = parseInt(key)
          }
        }
      }else{
        item.empresa = 0
        item.revenda = 0
      }

      if(item.status){
        for (var key in this.state.statuses) {
          if(item.status === this.state.statuses[key]){
            item.status = parseInt(key)
          }
        }
      }else{
        item.status = 0
      }


      result.push(item)
    }

    this.setData(result)
  }

  setData(result){

    var data = result.map(item => {


      var status = this.state.statuses[item.status]
      var empresa = this.state.empresas[item.empresa]
      var revenda = (this.state.revendas[item.empresa]) ? this.state.revendas[item.empresa][item.revenda] : ''
      return {
        id: item.id,
        query: item.query,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => this.delete(item)}
              color="danger"
              className="remove"
              >
              <Close />
            </Button>
          </div>
        )
      };
    })

    this.setState({data})
  }

  get(){
    axios.get(`${consts.API_URL}/queries`)
    .then(resp => {
      this.setData(resp.data.result)
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                <Button
                  color="info"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowForm()}
                  >
                  Adicionar
                </Button>
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  filterable
                  columns={[
                    {
                      Header: "Palavra",
                      accessor: "query"
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {

//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = state => ({
  ShowForm: state.ShowForm
})
const mapDispatchToProps = dispatch => bindActionCreators({
  ShowForm,
  ShowBookingForm,
  save,
  // get
}, dispatch)
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(mapStateToProps, mapDispatchToProps)(MemberList)
