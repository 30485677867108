const INITIAL_USER = {
  name: '',
  cpf: '',
  email: '',
  profile: '',
  vendedor: ''
}
const INITIAL_STATE = {
    showForm: false,
    user: INITIAL_USER
}

export default (state = INITIAL_STATE, action) => {
    // console.log(INITIAL_USER)
    // console.log(state)
    // console.log(action)
    switch (action.type) {
        case 'USERS_SHOW_FORM':
            return { ...state, showForm: true, user: (action.payload === undefined) ? INITIAL_USER : action.payload }
        case 'USERS_HIDE_FORM':
            return { ...state, showForm: false, user: INITIAL_USER }
        case 'USERS_GET':
            return { ...state, list: action.payload }
        case 'USERS_GET_CLIENT':
          return { ...state, cliente: action.payload }
        case 'USERS_GET_USER':
          return { ...state, user: {...state.user, name: action.payload.NOME, vendedor: action.payload.VENDEDOR}}
        default:
            return state
    }
}
