const INITIAL_COUPON = {
  description: "",
  code: "",
  amount: "",
  limit: "",
};
const INITIAL_STATE = {
  showForm: false,
  coupon: INITIAL_COUPON,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_COUPON);
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case "COUPONS_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        coupon: action.payload === undefined ? INITIAL_COUPON : action.payload,
      };
    case "COUPONS_HIDE_FORM":
      return { ...state, showForm: false, coupon: INITIAL_COUPON };
    case "COUPONS_GET":
      return { ...state, list: action.payload };
    case "COUPONS_GET_CLIENT":
      return { ...state, cliente: action.payload };
    case "COUPONS_GET_COUPON":
      return {
        ...state,
        coupon: { ...state.coupon, name: action.payload.NOME, vendedor: action.payload.VENDEDOR },
      };
    default:
      return state;
  }
};
