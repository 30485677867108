import axios from "axios";
import consts from "../../consts";

export function ShowForm(item) {
  // console.log({ item });
  return { type: "COUPONS_SHOW_FORM", payload: item };
}

export function HideForm() {
  return { type: "COUPONS_HIDE_FORM" };
}

export function save(coupon) {
  // console.log({ coupon });
  return (dispatch) => {
    if (coupon.id) {
      axios
        .put(`${consts.API_URL}/coupons/${coupon.id}`, coupon)
        .then((resp) => {
          // console.log(resp.data)
          const configNotification = {
            message: "Cupom atualizado com sucesso",
            color: "success",
          };
          dispatch([
            { type: "MAIN_SHOW_NOTIFICATION", showNotification: true, payload: configNotification },
            { type: "COUPONS_HIDE_FORM" },
          ]);
        })
        .catch((e) => {
          // console.log({ e });
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach((error) => {
              // toastr.error('Erro', error))
              const configNotification = {
                message: error,
                color: "danger",
              };
              dispatch([
                {
                  type: "MAIN_SHOW_NOTIFICATION",
                  showNotification: true,
                  payload: configNotification,
                },
              ]);
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
              message: e.response.data.message,
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
              message: "Erro inesperado.",
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          }
        });
    } else {
      axios
        .post(`${consts.API_URL}/coupons`, coupon)
        .then((resp) => {
          // console.log(resp)
          const configNotification = {
            message: "Cupom cadastrado com sucesso",
            color: "success",
          };
          dispatch([
            { type: "MAIN_SHOW_NOTIFICATION", showNotification: true, payload: configNotification },
            { type: "COUPONS_HIDE_FORM" },
          ]);
        })
        .catch((e) => {
          // console.log({ e });
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach((error) => {
              // toastr.error('Erro', error))
              const configNotification = {
                message: error,
                color: "danger",
              };
              dispatch([
                {
                  type: "MAIN_SHOW_NOTIFICATION",
                  showNotification: true,
                  payload: configNotification,
                },
              ]);
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
              message: e.response.data.message,
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
              message: "Erro inesperado.",
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          }
        });
    }
  };
}
