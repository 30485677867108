import React from "react";

// React Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import consts from '../../consts'
import {
  HideBookingForm,
  save,
  getClient,
  clearClient
} from './Actions'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment"

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import If from '../../common/operators/If'

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      empresa: "",
      revenda: "",
      proposta: "",
      cliente: "",


      category: "",
      // register form
      registerEmail: "",
      registerEmailState: "",
      registerPassword: "",
      registerPasswordState: "",
      registerConfirmPassword: "",
      registerConfirmPasswordState: "",
      registerCheckbox: false,
      registerCheckboxState: "",
      // login form
      loginEmail: "",
      loginEmailState: "",
      loginPassword: "",
      loginPasswordState: "",
      // type validation
      required: "",
      requiredState: "",
      typeEmail: "",
      typeEmailState: "",
      number: "",
      numberState: "",
      url: "",
      urlState: "",
      equalTo: "",
      whichEqualTo: "",
      equalToState: "",
      // range validation
      minLength: "",
      minLengthState: "",
      maxLength: "",
      maxLengthState: "",
      range: "",
      rangeState: "",
      minValue: "",
      minValueState: "",
      maxValue: "",
      maxValueState: ""
    };
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
  }

  handleSimple = event => {
    // console.log('handleSimple: ', event)
    this.props.clearClient();
    this.setState({ [event.target.name]: event.target.value});
  };

  cancelButtonClick(){
    this.props.clearClient()
    this.props.HideBookingForm()
  }

  saveButtonClick(){
    const me = JSON.parse(localStorage.getItem(consts.UserKey))
    // console.log(me)
    var plate = {...this.props.booking_plate}
    plate.empresa = this.state.empresa
    plate.revenda = this.state.revenda
    plate.proposta = this.state.proposta
    plate.cliente = this.props.cliente
    plate.status = 1
    plate.reserved_by = me.id
    // console.log(plate)
    // var plate = {
      // name: this.state.description
    // }
    this.props.save(plate)
  }

  searchButtonClick(){
    // console.log(this.state)
    var values = {
      "empresa" : this.state.empresa,
    	"revenda":this.state.revenda,
    	"proposta" : this.state.proposta
    }
    this.props.getClient(values)
  }



  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    this.props.clearClient();
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value <= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Reservar placa {this.props.booking_plate.name}</h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>

                  <GridItem xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Empresa
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.empresa}
                        onChange={this.handleSimple}
                        inputProps={{
                          name: "empresa",
                          id: "simple-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Empresa
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="1"
                        >
                          Volkswagen
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="5"
                        >
                          Ford
                        </MenuItem>

                      </Select>
                    </FormControl>
                  </GridItem>

                  <If test={this.state.empresa === "1"}>
                    <GridItem xs={12}>
                      <br/>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Revenda
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.revenda}
                          onChange={this.handleSimple}
                          inputProps={{
                            name: "revenda",
                            id: "simple-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Revenda
                          </MenuItem>

                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="1"
                          >
                            Sia
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="2"
                          >
                            Ceilândia
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="3"
                          >
                            Taguatinga
                          </MenuItem>

                        </Select>
                      </FormControl>
                    </GridItem>
                  </If>

                  <If test={this.state.empresa === "5"}>
                    <GridItem xs={12}>
                      <br/>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Revenda
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.revenda}
                          onChange={this.handleSimple}
                          inputProps={{
                            name: "revenda",
                            id: "simple-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Revenda
                          </MenuItem>

                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="1"
                          >
                            Taguatinga
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="2"
                          >
                            Ceilândia
                          </MenuItem>

                        </Select>
                      </FormControl>
                    </GridItem>
                  </If>

                  <GridItem xs={12}>
                    <br/>
                    <CustomInput
                      success={this.state.proposta === "success"}
                      error={this.state.proposta === "error"}
                      labelText={
                        <span>
                          Proposta <small>(obrigatório)</small>
                        </span>
                      }
                      id="proposta"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => this.change(event, "proposta", "length", 1)
                      }}
                    />
                  <If test={this.props.cliente !== ''}>
                      <CustomInput
                        success={this.state.cliente === "success"}
                        error={this.state.cliente === "error"}
                        labelText={
                          <span>
                            {(this.props.cliente === '') ? 'Buscar cliente' : 'Cliente'}
                          </span>
                        }
                        id="client"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.props.cliente,
                          placeholder: "Disabled",
                        }}
                        />
                      </If>
                  </GridItem>

                </GridContainer>

              </form>
            </CardBody>
            <CardFooter>
                <Button onClick={() => this.cancelButtonClick()}>
                  Cancelar
                </Button>
                <If test={this.props.cliente === ''}>
                  <Button color="warning" onClick={() => this.searchButtonClick()}>
                    Buscar no Bravos
                  </Button>
                </If>
                <If test={this.props.cliente !== ''}>
                  <Button color="info" onClick={() => this.saveButtonClick()}>
                    Reservar
                  </Button>
                </If>
            </CardFooter>
          </Card>
        </GridItem>

      </GridContainer>
    );
  }
}

const mapStateToProps = state => ({
    HideBookingForm: state.plates.HideBookingForm,
    booking_plate: state.plates.booking_plate,
    cliente: state.plates.cliente,
})
const mapDispatchToProps = dispatch => bindActionCreators({
  HideBookingForm,
  save,
  getClient,
  clearClient
}, dispatch)
const ReturnClassDecorated = withStyles(validationFormsStyle)(ValidationForms);
export default connect(mapStateToProps, mapDispatchToProps)(ReturnClassDecorated)
