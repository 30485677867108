import axios from 'axios'
import consts from '../../consts'

export function ShowForm(){
    return { type: 'PLATES_SHOW_FORM' }
}

export function HideForm(){
    return { type: 'PLATES_HIDE_FORM' }
}

export function ShowBookingForm(item){
  // console.log('ShowBookingForm')

    return { type: 'PLATES_SHOW_BOOKING_FORM', payload: item }
}

export function HideBookingForm(){
  // console.log('HideBookingForm')
    return { type: 'PLATES_HIDE_BOOKING_FORM' }
}

export function get(){
  return dispatch => {
      axios.get(`${consts.API_URL}/queries`)
      .then(resp => {
          dispatch([
            { type: 'PLATES_GET', payload: resp.data }
          ])
      })
    }
}

export function clearClient(){
  return { type: 'PLATES_GET_CLIENT', payload: '' }
}

export function getClient(values){
  return dispatch => {
    axios.post(consts.API_BRAVOS_URL, values)
    .then(resp => {
      console.log(resp.data)
      if(resp.data.status === 'error'){
        const configNotification = {
              message: resp.data.message,
              color: 'danger'
            }
        dispatch([
            { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
            { type: 'PLATES_GET_CLIENT', payload: '' }
        ])
      }else{
        dispatch([
          { type: 'PLATES_GET_CLIENT', payload: resp.data.client.NOME }
        ])
      }
    })
  }
}

export function save(query){
  return dispatch => {
    if(query.id){
      axios.put(`${consts.API_URL}/queries/${query.id}`, query)
      .then(resp => {
        // console.log(resp.data.data)
        const configNotification = {
          message: 'Palavra alterada com sucesso.',
          color: 'success'
        }
        dispatch([
          { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
          { type: 'PLATES_HIDE_BOOKING_FORM' },
          // { type: 'PLATES_GET_CLIENT', payload: '' }
        ])
      })
    }else{
      axios.post(`${consts.API_URL}/queries`, query)
      .then(resp => {
        // console.log(resp)
        const configNotification = {
          message: 'Palavra adicionada com sucesso',
          color: 'success'
        }
        dispatch([
          { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
          { type: 'PLATES_HIDE_FORM' }
        ])
      })
    }
  }
}
