import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

// import TabReducer from '../common/tab/tabReducer'
// import AuthReducer from '../auth/authReducer'
import AuthReducer from "./modules/auth/authReducer";
// import MemberReducer from './modules/members/Reducer'
// import GroupsReducer from './modules/groups/Reducer'
import MainReducer from "./routes/Reducer";
// import QueryReducer from './modules/queries/Reducer'
import UserReducer from "./modules/users/Reducer";
import PDVReducer from "./modules/pdvs/Reducer";
import CustomerReducer from "./modules/customers/Reducer";
import SettingsReducer from "./modules/settings/Reducer";
import TransfersReducer from "./modules/transfers/Reducer";
import BannersReducer from "./modules/banners/Reducer";
import CouponReducer from "./modules/coupons/Reducer";

const rootReducer = combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  users: UserReducer,
  pdvs: PDVReducer,
  customers: CustomerReducer,
  // queries: QueryReducer,
  auth: AuthReducer,
  main: MainReducer,
  settings: SettingsReducer,
  transfers: TransfersReducer,
  banners: BannersReducer,
  coupons: CouponReducer,
});

export default rootReducer;
