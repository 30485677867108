import Dashboard from "views/Dashboard/Dashboard.jsx";
// import Buttons from "views/Components/Buttons.jsx";
// import GridSystem from "views/Components/GridSystem.jsx";
// import Panels from "views/Components/Panels.jsx";
// import SweetAlert from "views/Components/SweetAlert.jsx";
// import Notifications from "views/Components/Notifications.jsx";
// import Icons from "views/Components/Icons.jsx";
// import Typography from "views/Components/Typography.jsx";
// import RegularForms from "views/Forms/RegularForms.jsx";
// import ExtendedForms from "views/Forms/ExtendedForms.jsx";
// import ValidationForms from "views/Forms/ValidationForms.jsx";
// import Wizard from "views/Forms/Wizard.jsx";
// import RegularTables from "views/Tables/RegularTables.jsx";
// import ExtendedTables from "views/Tables/ExtendedTables.jsx";
// import ReactTables from "views/Tables/ReactTables.jsx";
// import GoogleMaps from "views/Maps/GoogleMaps.jsx";
// import FullScreenMap from "views/Maps/FullScreenMap.jsx";
// import VectorMap from "views/Maps/VectorMap.jsx";
// import Charts from "views/Charts/Charts.jsx";
// import Calendar from "views/Calendar/Calendar.jsx";
// import Widgets from "views/Widgets/Widgets.jsx";
// import UserProfile from "views/Pages/UserProfile.jsx";
// import TimelinePage from "views/Pages/Timeline.jsx";
// import RTLSupport from "views/Pages/RTLSupport.jsx";

// Modules
import UsersModule from "../modules/users/Index";
import MeModule from "../modules/users/Me";
import PasswordModule from "../modules/users/Password";
// import GroupModule from "../modules/groups/Index"
import QueriesModule from "../modules/queries/Index";
import DashboardModule from "../modules/dashboard/Index";
import DashboardPowerModule from "../modules/dashboard/Power";
import DashboardChartsModule from "../modules/dashboard/Charts";
import DashboardDashboardModule from "../modules/dashboard/Dashboard";
// import LoginModule from '../modules/auth/Login'
import PlacesModule from "../modules/places/Index";
import PDVsModule from "../modules/pdvs/Index";
import CustomersModule from "../modules/customers/Index";
import SettingsModule from "../modules/settings/Index";
import TransfersModule from "../modules/transfers/Index";
import BannersModule from "../modules/banners/Index";
import CouponsModule from "../modules/coupons/Index";
// End Modules

// import pagesRoutes from "./pages.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import CouponIcon from "@material-ui/icons/ExposurePlus1";
import MapIcon from "@material-ui/icons/Map";
import SettingsIcon from "@material-ui/icons/Settings";
import TransfersIcon from "@material-ui/icons/Loop";
// import Image from "@material-ui/icons/Image";
import Apps from "@material-ui/icons/Apps";
// import CategoryIcon from "@material-ui/icons/Category"
import GroupIcon from "@material-ui/icons/Group";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
// import ContentPaste from "@material-ui/icons/ContentPaste";
// import GridOn from "@material-ui/icons/GridOn";
// import Place from "@material-ui/icons/Place";
// import WidgetsIcon from "@material-ui/icons/Widgets";
// import Timeline from "@material-ui/icons/Timeline";
// import DateRange from "@material-ui/icons/DateRange";
// @material-ui/icons
import Face from "@material-ui/icons/Face";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardModule,
  },
  {
    path: "/banners",
    name: "Banners",
    icon: ViewCarouselIcon,
    component: BannersModule,
    profiles: ["manager"],
  },
  {
    path: "/coupons",
    name: "Cupons",
    icon: CouponIcon,
    component: CouponsModule,
    profiles: ["manager"],
  },
  {
    path: "/customers",
    name: "Consumidores",
    icon: Face,
    component: CustomersModule,
    profiles: ["manager"],
  },
  {
    path: "/pdvs",
    name: "Pontos de venda",
    icon: MapIcon,
    component: PDVsModule,
    profiles: ["manager"],
  },
  {
    path: "/users",
    name: "Usuários",
    icon: GroupIcon,
    component: UsersModule,
    profiles: ["manager"],
  },
  {
    path: "/transfers",
    name: "Transações",
    icon: TransfersIcon,
    component: TransfersModule,
    profiles: ["manager"],
  },
  {
    path: "/settings",
    name: "Configurações",
    icon: SettingsIcon,
    component: SettingsModule,
    profiles: ["manager"],
  },

  {
    path: "/me",
    name: "Meu perfil",
    icon: DirectionsCarIcon,
    component: MeModule,
    hidden: true,
  },
  {
    path: "/password",
    name: "Mudar senha",
    icon: DirectionsCarIcon,
    component: PasswordModule,
    hidden: true,
  },

  { redirect: true, path: "/", pathTo: "/dashboard", name: "Palavras" },
];
export default dashRoutes;
