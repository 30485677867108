const INITIAL_STATE = {
    showNotification: false,
    configNotification: {
      message: '',
      color: 'success'
    }
}

export default (state = INITIAL_STATE, action) => {
    // console.log(state)
    // console.log(action)
    switch (action.type) {
        case 'MAIN_SHOW_NOTIFICATION':
            return { ...state, showNotification: true, configNotification: action.payload }
        case 'MAIN_HIDE_NOTIFICATION':
            return { ...state, showNotification: false }
        default:
            return state
    }
}
